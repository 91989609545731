
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{ margin: "25px" }}> The Truth About Chas Roberts</h1>
        <h3 style={{ margin: "25px" }}> Monopolizing AC installs, creating planned obsolescence and forcing inhumane work conditions.</h3>
        <p style={{ margin: "25px" }}>
          They have a stranglehold on the market in Arizona and are the largest residential company in the states.
          95% of all new houses in Arizona are Chas Roberts
          installs and they are doing 325 installs a week.
          <br /> <br /> The amount of aluminum they are choosing to use per house only saves a few dollars as opposed to using copper which is consistently reliable. Using aluminum to copper causes the unit to be prone to leaking refrigerant for the entire lifespan of the house. The only way to change it would be to tear open all the walls and take it out.  However, their contract only requires them to fix it for free for two years after that the home owner will have to pay out of pocket and it is not cheap, it is a nightmare and takes hours to fix.
          <br /><br />
          Chas Roberts also enforces exclusivity contracts with construction companies literally forcing and controlling through their monopoly our world into unethical contracts.
          <br /> <br /> As if that wasn't bad enough, Chas Roberts treats their employees so badly that there is a high turnover rate for technicians resulting in individuals having to work 80+ hours a week, sometimes 20+ hours in one day. So if your house is still in that time frame their is no guarantee that it will even be fixed properly until it will eventually leak again.
          <br /> <br /> These practices are highly unethical, revolving purely around monetary gain for their company and not around creating a better world.
          <br /> <br />
          -An Anonymous Employee
        </p>

      </header>
    </div>
  );
}

export default App;
